<template>
  <v-form ref="form" v-model="valid">
    <v-row>
      <v-col cols="6">
        <v-select
          outlined
          label="Water Source"
          :items="items.waterSource"
          v-model="formData.water_source"
          :rules="rules.requiredRule"
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-checkbox
          label="Reverse Osmosis system installed"
          v-model="formData.reverse_osmosis"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-select
          outlined
          label="Water Installed by"
          :items="items.installedBy"
          v-model="formData.installed.by"
        ></v-select>
      </v-col>
      <v-col cols="6">
        <div class="d-flex">
          <v-text-field
            outlined
            label="Year installed"
            v-model="formData.installed.year"
          ></v-text-field>
          <v-checkbox
            class="checkbox-notsure"
            v-model="formData.installed.year_dont_know"
            label="Not sure"
          ></v-checkbox>
        </div>
      </v-col>
    </v-row>
    <div v-if="formData.water_source == 'Private Well'">
      <v-text-field outlined label="Well Location" v-model="formData.well_location"></v-text-field>
      <div>
        <v-checkbox
          v-model="formData.well_location_sketch.exists"
          label="I have a well location sketch to archive"
        ></v-checkbox>
        <file-upload
          v-if="formData.well_location_sketch.exists"
          :error.sync="files.errors.well_location_sketch"
          :file.sync="files.well_location_sketch"
          :report="formData.well_location_sketch.sketch"
        ></file-upload>
      </div>
    </div>
    <div class="text-center">
      <FormAction @click="submit()" :loading="loading" :update="!!data" @cancel="cancel()" />
    </div>
  </v-form>
</template>

<script>
import rules from '@/mixins/rules';
export default {
  mixins: [rules],
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  components: {
    FileUpload: () => import('@/components/FileUpload.vue'),
    FormAction: () => import('@/components/Feature/FormAction.vue')
  },
  data: () => ({
    items: {
      waterSource: ['Private Well', 'Municipal Water', 'Bottled Water', 'Other'],
      installedBy: ['Atlantic', 'Brian Davis', 'Frank Capelo', 'Other']
    },
    formData: {
      installed: {},
      well_location_sketch: { sketch: {} }
    },
    valid: true,
    files: {
      well_location_sketch: null,
      description: {},
      errors: { well_location_sketch: false }
    },
    loading: false,
    origData: {}
  }),
  methods: {
    setData() {
      if (this.data) {
        this.origData = this.copyObject(this.data);
        this.formData = { ...this.data };
      }
    },
    async uploadDoc() {
      if (this.files.well_location_sketch) {
        let doc = await this.uploadFile(this.files.well_location_sketch, {
          description: this.files.description.well_location_sketch,
          feature: 'water',
          parcelID: this.$route.params.id || ''
        });
        this.formData.well_location_sketch.sketch = {
          ...doc
        };
        this.files.well_location_sketch = null;
      }
    },
    async submit() {
      this.$refs.form.validate();
      if (this.valid) {
        this.loading = true;
        await this.uploadDoc();
        this.$emit('submit', this.formData);
        this.loading = false;

        this.updateMetrics({ feature: 'water_information', action: 'update' });
      }
    },
    cancel() {
      if (Object.keys(this.origData).length) {
        this.formData = JSON.parse(JSON.stringify(this.origData));
      }
      this.$emit('cancel');
    }
  },
  watch: {
    data: {
      immediate: true,
      handler: 'setData'
    }
  }
};
</script>

<style></style>
