import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"500"},model:{value:(_vm.edit.dialog),callback:function ($$v) {_vm.$set(_vm.edit, "dialog", $$v)},expression:"edit.dialog"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle,[_vm._v("Edit "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.edit.dialog = !_vm.edit.dialog}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VContainer,[_c('base-form',{attrs:{"data":_vm.edit.data,"update":""},on:{"submit":_vm.updateWaterTestHistory,"cancel":function($event){_vm.edit.dialog = false}},scopedSlots:_vm._u([{key:"button",fn:function(){return [_vm._v(" Update "),_c(VIcon,[_vm._v("mdi-content-save")])]},proxy:true}])})],1)],1)],1),_c(VDialog,{attrs:{"max-width":"400"},model:{value:(_vm.dlt.dialog),callback:function ($$v) {_vm.$set(_vm.dlt, "dialog", $$v)},expression:"dlt.dialog"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle),_c(VCardText,[_vm._v(" Are you sure? Do you want to delete this item? ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteItem()}}},[_vm._v("Yes")]),_c(VBtn,{attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.dlt.dialog = false}}},[_vm._v("No")]),_c(VSpacer)],1)],1)],1),_c('base-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.waterTestHistory,"dense":""},scopedSlots:_vm._u([{key:"item.nitrate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(Number(item.nitrate).toFixed(1)))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('DisplayFile',{attrs:{"file":item.report}}),_c('UpdateDelete',{on:{"update":function($event){return _vm.editItem(item.index)},"delete":function($event){return _vm.showDeleteDialog(item.index)}}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }