<template>
  <v-form ref="form" v-model="valid">
    <v-row>
      <v-col cols="6" lg="7" sm="12">
        <v-menu
          ref="date"
          v-model="dialogs.datepicker"
          :close-on-content-click="false"
          transition="scale-transition"
          autocomplete="off"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :rules="rules.requiredRule"
              dense
              v-model="formData.testDate"
              label="Water Test Date"
              persistent-hint
              outlined
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formData.testDate"
            no-title
            @input="dialogs.datepicker = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="6" lg="5" sm="12" class="mt-row-sm-md">
        <v-text-field
          autofocus
          outlined
          type="number"
          label="Nitrate mg/L"
          :rules="rules.requiredRule"
          v-model="formData.nitrate"
          dense
        >
        </v-text-field>
      </v-col>
    </v-row>

    <base-combobox
      :items="labItems"
      v-model="formData.lab"
      label="Lab"
      dense
      outlined
      :rules="rules.requiredRule"
    ></base-combobox>

    <v-textarea
      rows="3"
      outlined
      label="Remarks"
      v-model="formData.remarks"
      dense
      auto-grow
    ></v-textarea>
    <div>
      <div>
        <v-checkbox
          @change="handleReport"
          v-model="formData.report.exists"
          label="I have a report to store too"
        ></v-checkbox>
      </div>

      <v-slide-y-transition>
        <file-upload
          :error.sync="files.errors.report"
          :report="formData.report"
          v-show="formData.report.exists"
          :file.sync="files.report"
        ></file-upload>
      </v-slide-y-transition>
    </div>
    <div class="text-center">
      <FormAction :loading="loading" @click="submit()" @cancel="cancel()" :update="update" />
    </div>
  </v-form>
</template>

<script>
import rules from '@/mixins/rules';
import audio from '@/mixins/audio';
import utils from '@/mixins/utils';

export default {
  props: ['data', 'disabled', 'update'],
  mixins: [rules, audio, utils],

  components: {
    FileUpload: () => import('@/components/FileUpload'),
    FormAction: () => import('@/components/Feature/FormAction.vue')
  },

  created() {
    this.formData = this.copyObject(this.defaultFormData);
  },
  data: () => ({
    valid: true,
    defaultFormData: {
      testDate: '',
      report: {}
    },
    formData: {},
    dialogs: {
      datepicker: false
    },
    file: null,
    fileError: false,
    loading: false,
    files: { report: null, errors: { report: false } },
    labItems: ['Barnstable County', 'Bluedrop', 'Analytical Balance', 'Other'],
    search: { lab: '' }
  }),

  mounted() {
    this.setData();
  },
  methods: {
    setData() {
      if (this.data) {
        if (!this.data.report) {
          this.data.report = {};
        }
        this.formData = this.copyObject(this.data);
      } else {
        this.formData = this.copyObject(this.defaultFormData);
      }
    },
    resetForm() {
      this.formData = this.copyObject(this.defaultFormData);
      this.$refs.form.resetValidation();
    },
    async uploadReport() {
      if (this.files.report) {
        let report = await this.uploadFile(this.files.report, {
          feature: 'water',
          parcelID: this.$route.params.id || ''
        });
        this.formData.report = { ...this.formData.report, ...report };
        this.files.report = null;
      }
    },
    async submit() {
      this.$refs.form.validate();
      if (this.valid) {
        this.loading = true;
        if (!this.data) {
          this.formData.timestampCreated = new Date();
        }
        this.formData.timestampModified = new Date();
        this.formData.nitrate = Number(this.formData.nitrate);
        if (this.files.report) {
          await this.uploadReport();
        }
        this.loading = false;
        this.$emit('submit', this);
        this.playSplash();
      }
    },
    handleReport() {
      if (!this.formData.report.exists) {
        this.formData.report = {};
        this.files.report = null;
      }
    },
    showFile() {
      this.newWindow(this.formData.reportURL);
    },
    cancel() {
      this.$emit('cancel');
      if (!this.data) {
        this.resetForm();
      }
    }
  },
  watch: {
    data: {
      handler: 'setData'
    }
  }
};
</script>

<style></style>
