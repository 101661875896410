import user from '@/mixins/user'
import { waterTestRef } from '../firebase/db';
import { useDocumentHistory } from '../services/documentHistory'
export default {
  mixins: [user],
  methods: {
    async getWaterTestHistory(parcelId) {
      let res = await waterTestRef.doc(parcelId).get()
      if (res.exists) {
        return res.data()
      } else {
        return null
      }
    },
    saveWaterTestHistory(parcelId, data) {
      let source = this.$store.getters.waterSource
      return waterTestRef.doc(parcelId).set({ source: source, data: data }).then(()=>{
        const {addDocHistory} = useDocumentHistory()
        addDocHistory({
          id: parcelId,
          data: {source, data},
          docCollection: waterTestRef.id
        })
      })
    },
    saveWaterData(id, data) {
      return waterTestRef.doc(id).set(data).then(()=> {
        const {addDocHistory} = useDocumentHistory()
        addDocHistory({
          id,
          data,
          docCollection: waterTestRef.id
        })
      })
    }
  }
}
