<template>
  <div>
    <base-form @submit="addWaterHistory" @cancel="$emit('cancel')" :disabled="!permissions.create">
      <template v-slot:button>Save <v-icon>mdi-content-save</v-icon></template>
    </base-form>
  </div>
</template>

<script>
import parcel from '@/mixins/parcel';
import { mapState } from 'vuex';
export default {
  mixins: [parcel],
  components: {
    BaseForm: () => import('@/components/Water/BaseForm')
  },
  computed: {
    ...mapState(['permissions'])
  },
  methods: {
    addWaterHistory(e) {
      this.$store.commit('addWaterTestHistory', { ...e.formData });
      e.resetForm();
      this.$emit('updated');

      this.updateMetrics({ feature: 'water', action: 'create' });
    }
  }
};
</script>

<style></style>
